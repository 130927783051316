/*** meet page css start ***/
@font-face {
  font-family: "ProximaSoft-Regular";
  src: url("../../fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/ProximaSoft-Regular.otf") format("opentype"),
    url("../../fonts/ProximaSoft-Regular.woff") format("woff"),
    url("../../fonts/ProximaSoft-Regular.ttf") format("truetype"),
    url("../../fonts/ProximaSoft-Regular.svg#ProximaSoft-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Medium";
  src: url("../../fonts/EuclidFlex-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Regular";
  src: url("../../fonts/EuclidFlex-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/EuclidFlex-Regular.otf") format("opentype"),
    url("../../fonts/EuclidFlex-Regular.woff") format("woff"),
    url("../../fonts/EuclidFlex-Regular.ttf") format("truetype"),
    url("../../fonts/EuclidFlex-Regular.svg#EuclidFlex-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
html {
  height: 100%;
}
body {
  font-family: "ProximaSoft-Regular";
  height: 100%;
}
.version4 .menu_wrapper .drop-down .selected a span {
  font-weight: 500;
  color: rgba(56, 65, 124, 0.84);
  background: url(../../images/arrow.svg) no-repeat right;
  padding-right: 28px;
}
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 56px;
  padding: 15px;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #73799f;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 15px;
  color: rgba(56, 65, 124, 0.7);
}
/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
.meet-form-main {
  height: calc(100% - 83px);
  padding: 0;
}
.white-box {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.35);
  border-radius: 8px;
}
.meet-form {
  padding: 25px 20px 20px 20px;
  max-width: 362px;
  margin: -100px auto 0;
  width: 322px;
}
.meet-form h3 {
  font-family: "EuclidFlex-Medium";
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.9px;
  color: #38417c;
}
.version4 .fild-row .selected a {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #73799f;
  padding: 15px;
}
.version4 .fild-row .open-list .selected a {
  color: #38417c;
}
.options ul {
  top: -4px;
}
.primary-btn {
  background: #38417c;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  height: 60px;
  letter-spacing: -0.25px;
  font-family: "EuclidFlex-Medium";
  cursor: pointer;
}
.meet-form .primary-btn {
  margin-top: 12px;
}
.form-control {
  font-size: 18px;
  color: #38417c;
  border: 1px solid #bfc2d6;
  border-radius: 8px;
}
.fild-row .options ul li a {
  font-size: 19px;
  line-height: 18px;
  padding: 19px;
  border-top: 1px solid #bfc2d6;
}
.video-mute,
.speaker-mute,
.speaker-unmute,
.video-unmute,
.call-option-img {
  background: url(../../images/version4-images/video-mute.svg) no-repeat;
  width: 60px;
  display: block;
  height: 60px;
  background-size: 100%;
}
.speaker-mute {
  background: url(../../images/version4-images/mute.svg) no-repeat;
}
.end-call {
  background: url(../../images/version4-images/end-call.svg) no-repeat;
}
.video-unmute {
  background: url(../../images/version4-images/video-unmute.svg) no-repeat;
  /* position: absolute;
	top:0;
	left: 0px; */
  display: block;
  width: 60px;
  height: 60px;
  opacity: 1;
  transition: 0.4s;
}
.speaker-unmute {
  background: url(../../images/version4-images/unmute.svg) no-repeat;
  /* position: absolute; */
  top: -70px;
  left: -13px;
  height: 60px;
  width: 60px;
  display: block;
  background-size: 100%;
  opacity: 1;
  transition: 0.4s;
}
.mute-unmute-video .video-mute {
  opacity: 1;
}
.call-options-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  text-align: center;
  z-index: 9999999;
}
.call-options-main li {
  display: inline-block;
  margin: 0 8px;
  position: relative;
}
.call-settings {
  position: absolute;
  right: 20px;
  bottom: -10px;
}
.setting {
  background: url(../../images/version4-images/settings.svg) no-repeat;
  height: 80px;
  width: 80px;
  display: block;
  background-size: 100%;
  z-index: 9;
  position: relative;
}
.chat {
  background: url(../../images/message-icon.svg) no-repeat;
  /* height: 80px;
  width: 80px; */
  display: block;
  background-size: 100%;
  z-index: 9;
  position: relative;
  background-position: center center;
  margin-left: -16px;
  height: 80px;
  width: 80px;
  margin-top: -85px;
}
.setting-info {
  width: 175px;
  height: 40px;
  background: #192344;
  border-radius: 8px;
  font-family: "EuclidFlex-Regular";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.45px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  position: absolute;
  opacity: 0;
  right: 0;
  bottom: 90px;
  transition: 0.4s;
}
.open-setting .setting-info {
  opacity: 1;
}
.fild-row .options {
  position: relative;
}
.mute-unmute .speaker-unmute,
.mute-unmute-video .video-unmute {
  opacity: 1;
}
#m-code.code-apply {
  background: url(../../images/version4-images/code-arrow.svg) no-repeat 97%
    center;
}
/* form input::placeholder {
color: #38417C !important;
} */
/*** meet page css end ***/
/*** video activate page css start ***/
.video-bg,
.request-submitted {
  background-size: cover;
  height: calc(100% - 83px);
}
/*** request submitted page css start ***/
.call-left {
  float: left;
  width: 73%;
  position: relative;
}
.call-img {
  background-repeat: no-repeat;
  background-position: top center;
  height: calc(100vh - 83px);
  background-size: cover;
}
.volume-icon span {
}
.call-options-main .volume-icon {
  position: absolute;
  left: 50px;
  top: 23px;
}
.volume-icon > span {
  font-size: 14px;
  letter-spacing: -0.45px;
  color: #fff;
  font-family: "EuclidFlex-Regular";
  background: url(../../images/version4-images/volume.svg) no-repeat right
    center;
  padding: 10px 30px 10px 0;
}
.call-right {
  float: right;
  width: 27%;
  position: relative;
}
.call-img-main {
  position: relative;
  margin-top: 16px;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
}
.call-img-main.active {
  border: 2px solid #38417c;
}
.user-call-info-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
  padding: 0 20px 0 20px;
  z-index: 9999;
}
.usname {
  font-size: 16px;
  letter-spacing: -0.45px;
  color: #fff;
  font-family: "EuclidFlex-Medium";
  float: left;
  margin-bottom: 25px;
  font-weight: 500;
}
.user-call-info ul li {
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  margin-left: 7px;
}
.user-call-info ul li:first-child {
  margin-left: 0;
}
.user-call-info .video-m,
.user-call-info .speaker-mute {
  height: 23px;
  width: 23px;
  background-size: 100%;
}
.user-call-info .speaker-unmute {
  height: 23px;
  width: 23px;
  background-size: 100%;
  top: -3px;
  left: -5px;
}
.user-call-info {
  float: right;
}
.call-info-tabs .nav-tabs {
  border-bottom: 0;
  justify-content: center;
}
.vol-imgs img {
  vertical-align: middle;
}
.call-info-tabs .tab-content {
  padding: 0 20px 10px 12px;
}
.call-img-main > div > img {
  width: 100%;
  border-radius: 8px;
}
.call-info-tabs .nav-tabs .nav-item.show .nav-link,
.call-info-tabs .nav-tabs .nav-link.active {
  color: #343642;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #38417c;
}
.call-info-tabs .nav-tabs .nav-link {
  font-family: "EuclidFlex-Medium";
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: rgba(56, 65, 124, 0.84);
  border: 0;
  border-bottom: 2px solid transparent;
  text-transform: unset;
  padding: 18px 0 12px 0;
}
.call-info-tabs .nav-tabs .nav-item {
  float: left;
  width: 50%;
  text-align: center;
}
.users-img {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.name-user {
  background: #ffc700;
  font-family: "EuclidFlex-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.45px;
  color: #ffffff;
  text-transform: uppercase;
}
.user-chatting {
  display: table;
  padding-left: 10px;
}
.user-chatting h5 {
  color: rgba(25, 35, 68, 0.6);
  font-size: 14px;
  font-family: "EuclidFlex-Regular";
}
.user-chatting p {
  color: #343642;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  font-family: "ProximaSoft-Regular";
}
.user-chatting p a {
  clear: both;
  color: #ffc700;
}
.user-chat-main {
  margin-bottom: 20px;
}
#chat {
  /* padding-top: 50px; */
}
.call-right .type-msg {
  width: calc(100% - 40px);
  left: 20px;
}
.call-right .type-msg .common-input {
  width: calc(100% - 126px);
  height: 47px;
  border: 1px solid #d2d4de;
  padding: 14px;
}
.call-right .type-msg .primary-btn {
  float: right;
  width: 106px;
  font-size: 22px;
  height: 47px;
}
.call-right .tab-content > .active {
  opacity: 1;
}
/*** joining calls page css start ***/
.joining-calls-main {
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(100% - 83px);
  z-index: 9999;
  background: rgba(25, 35, 68, 0.9);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.joining-loading h6 {
  color: #fff;
  font-family: "EuclidFlex-Medium";
  font-size: 22px;
  align-items: center;
  letter-spacing: -0.45px;
  color: #ffffff;
}
/*** inthis page css start ***/
/* .gradient-bg{
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.54%, rgba(0, 0, 0, 0.65) 100%);
	background-color:#343642;
} */
/* .inthis-call-img {
	height: auto;
	min-height: 934px;
} */
.caller-main {
  position: absolute;
  right: 110px;
  bottom: 10px;
}
.call-info-tabs .nav-tabs .nav-link span {
  position: relative;
}
.show-dot:before {
  position: absolute;
  width: 7px;
  height: 7px;
  right: -12px;
  top: 0px;
  background: #ff7259;
  border-radius: 100%;
  content: "";
}
.user-chat-main.more-space {
  margin-bottom: 100px;
}
.call-right .custom-scrl-content {
  height: calc(100vh - 132px);
}
.call-right .mCSB_container {
  padding-right: 0;
}

.chat-input::placeholder {
  color: #38417c;
  font-size: 18px;
  mix-blend-mode: normal;
  opacity: 0.6;
}

.chat-input {
  color: #38417c !important;
  font-size: 18px !important;
  mix-blend-mode: normal;
  opacity: 1 !important;
}

.form-group select {
  border: 1px solid #D1D4E3;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  /* or 121% */

  letter-spacing: -0.25px;

  /* Primary Colour */

  color: #38417C;
  font-family: "ProximaSoft-Regular";
}